import React, { Component } from "react";
import Layout from "./layout";
import { graphql } from "gatsby";
import SEO from "./seo";

// ? Static Query
// * Can be used anywhere, but it does not accept parameters and it cannot use context

// ? Page Query
// * Must be used on pages

export default class postLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data;
    const { location } = this.props;
    return (
      <Layout location={location}>
        <SEO
          title={markdownRemark.frontmatter.title}
          description={
            markdownRemark.excerpt || "Tischfußball in Mannheim. Neu gemacht."
          }
        />
        <article>
          <h1>{markdownRemark.frontmatter.title}</h1>
          <h6>
            <time>{markdownRemark.frontmatter.date}</time>
            {markdownRemark.frontmatter.author && (
              <span>
                {" - von "}
                {markdownRemark.frontmatter.author}
              </span>
            )}
          </h6>
          <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
        </article>
      </Layout>
    );
  }
}

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        date(formatString: "DD. MMMM YYYY", locale: "de")
        slug
        author
      }
    }
  }
`;
